import { Permission, User } from "shared/models";
import Api from "../utils/api";

export default class UsersApi extends Api {
    private static PREFIX = "/api/v1/users";

    public async list() {
        return (await this.axios.get(`${UsersApi.PREFIX}/`)).data;
    }

    public async add(user: User, password?: string) {
        return (await this.axios.post(`${UsersApi.PREFIX}/add`, {
            user,
            password
        })).data;
    }

    public async update(user: User, password?: string) {
        return (await this.axios.post(`${UsersApi.PREFIX}/update`, {
            user,
            password
        })).data;
    }

    public async delete(user: User) {
        return (await this.axios.post(`${UsersApi.PREFIX}/delete`, {
            user
        })).data;
    }

    public async listPermissions(userId: string) {
        return (await this.axios.get(`${UsersApi.PREFIX}/permissions/${userId}`)).data;
    }

    public async updatePermissions(userId: string, permissions: Permission[]) {
        return (await this.axios.post(`${UsersApi.PREFIX}/permissions/${userId}`, {
            permissions
        })).data;
    }

    public async getAvaliblePermissions() {
        return (await this.axios.get(`${UsersApi.PREFIX}/permissions`)).data;
    }
}