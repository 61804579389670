import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable } from "mobx";
import { NavLink } from "react-router-dom";
import Table from "../ui/Table";
import { Row, Col, Alert } from "react-bootstrap";
import { timeout } from "../lib/utils/utils";

@observer
export default class Finanaces extends React.Component<{ store: Store }> {
    @observable
    isLoading = true;
    @observable
    status: any[] = [];

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();
    }

    @action
    init = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("finances");
            if (!this.props.store.can("finances")) {
                throw new Error("No access!!!!");
            }

            this.isLoading = true;

            this.status = (await this.props.store.financesApi.statuse()).data;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    render() {
        const columns = [
            {
                Header: "ID",
                disableSortBy: false,
                accessor: "id",
            },
            {
                Header: "Month",
                disableSortBy: false,
                accessor: "for_month",
            },
            {
                Header: "Year",
                disableSortBy: false,
                accessor: "for_year",
            },
            {
                Header: "Last update",
                disableSortBy: false,
                accessor: "updated",
            },
            {
                Header: "Action",
                disableSortBy: true,
                accessor: "deleted",
                actions: (row: any) => {
                    return (
                        <>
                            <NavLink to={"/finanaces/" + row.original.id + "/invoices"}>Invoices</NavLink>&nbsp;
                            <NavLink to={"/finanaces/" + row.original.id + "/credit-notes"}>Credit notes</NavLink>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col className="mb-20">
                        <h2>Finances</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Table columns={columns} data={this.status} />
                    </Col>
                </Row>
            </>
        );
    }
}
