import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function Popup(props: { show: boolean; title: string; onClose: () => void; onSave?: () => void; children?: React.ReactNode; save?: string | React.ReactNode; disabled?: boolean; size?: "sm" | "lg" | "xl" }) {
    return (
        <>
            <Modal show={props.show} onHide={props.onClose} size={props.size}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose} disabled={props.disabled}>
                        Close
                    </Button>
                    {props.onSave && (
                        <Button variant="primary" onClick={props.onSave} disabled={props.disabled}>
                            {props.save ? props.save : "Save"}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}
