import Api from "../utils/api";

export default class TemplatesApi extends Api {
    private static PREFIX = "/api/v1/templates";


    version = async () => {
        return (await this.axios.get(`${TemplatesApi.PREFIX}/`, {})).data;
    }

    branches = async () => {
        return (await this.axios.get(`${TemplatesApi.PREFIX}/branches`)).data;
    }

    current_branch = async () => {
        return (await this.axios.get(`${TemplatesApi.PREFIX}/branches/current`)).data;
    }

    update = async (server: string) => {
        return (await this.axios.post(`${TemplatesApi.PREFIX}/update/${server}`, {})).data;
    }

    changes = async (server: string) => {
        return (await this.axios.get(`${TemplatesApi.PREFIX}/changes/${server}`)).data;
    }

    log = async (server: string) => {
        return (await this.axios.get(`${TemplatesApi.PREFIX}/log/${server}`)).data;
    }

    checkout = async (server: string, remoteBranch: string) => {
        return (await this.axios.post(`${TemplatesApi.PREFIX}/checkout/${server}`, { remoteBranch })).data;
    }
}