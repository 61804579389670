import Api from "../utils/api";

export default class SkinApi extends Api {
    private static PREFIX = "/api/v1/skins";

    public async list() {
        return (await this.axios.get(`${SkinApi.PREFIX}/`)).data;
    }

    public async list_only() {
        return (await this.axios.get(`${SkinApi.PREFIX}/only`)).data;
    }

    public async reload(skinId: string) {
        return (await this.axios.post(`${SkinApi.PREFIX}/reload/${skinId}`)).data;
    }

    public async reload_all() {
        return (await this.axios.post(`${SkinApi.PREFIX}/reload`)).data;
    }

    public async caches() {
        return (await this.axios.get(`${SkinApi.PREFIX}/cahces`)).data;
    }
}