import { Moment } from "moment";
import Api from "../utils/api";

export default class StoreApi extends Api {
    private static PREFIX = "/api/v1/store";

    public async get(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}`)).data;
    }

    public async skins(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/skins`)).data;
    }

    public async admins(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/admins`)).data;
    }

    public async domains(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/domains`)).data;
    }

    public async impersonate_admin(instance_name: string, storeId: number, adminId: number, domain: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/admins/impersonate`, {
            instance_name,
            adminId,
            storeId,
            domain
        })).data;
    }

    public async users(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/users`)).data;
    }

    public async impersonate_user(instance_name: string, storeId: number, userId: number, domain: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/users/impersonate`, {
            instance_name,
            userId,
            storeId,
            domain
        })).data;
    }

    public async impersonate_user_by_email(instance_name: string, storeId: number, email: string, domain: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/users/impersonate/by/email`, {
            instance_name,
            email,
            storeId,
            domain
        })).data;
    }

    public async apikeys(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/apikeys`)).data;
    }

    public async user_emails(storeId: number, purchaseOnly = false) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/emails?purchaseOnly=${purchaseOnly}`)).data;
    }

    public async usage(storeId: number, from: Moment, to: Moment) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/usage`, {
            from: from.format("DD-MM-YYYY"), to: to.format("DD-MM-YYYY")
        })).data;
    }

    public async create_backup(storeId: number, email: string, enc_password: string, send_backup: number) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/backup`, {
            email, enc_password, send_backup
        })).data;
    }

    public async deactivate(storeId: number, reason: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/decativate`, { reason })).data;
    }

    public async activate(storeId: number, instances: string[]) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/activate`, { instances })).data;
    }

    public async activate_all(storeId: number) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/activate/all`)).data;
    }

    public async load(storeId: number, instanceType: "api" | "app") {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/load`, { instanceType })).data;
    }

    public async load_by_instances(storeId: number, instances: string[]) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/load/by/instances`, { instances })).data;
    }

    public async notes(storeid: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeid}/data`)).data;
    }

    public async save_notes(storeid: number, data: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeid}/data`, { data })).data;
    }

    public async reload_config(storeid: number) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeid}/reload/config`)).data;
    }

    public async stripe_settings(storeId: string) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/stripe/settings`)).data;
    }

    public async update_stripe_settings(storeId: string, striperAccountId: string, fee: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/stripe/settings`, { striperAccountId, fee })).data;
    }

    public async email_configuration(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/email/settings`)).data;
    }

    public async backups_list(storeId: number, type: "auto" | "request") {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/backups/list/${type}`)).data;
    }

    public async backups_get(storeId: number, path: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/backups/get`, { path })).data;
    }

    public async link_admin(instance_name: string, storeId: number, adminId: number, email: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/admin/${adminId}/link`, { instance_name, email })).data;
    }

    public async admin_stores(instance_name: string, storeId: number, adminId: number) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/admin/${adminId}/stores`, { instance_name })).data;
    }

    public async remove_admin_stores(instance_name: string, storeId: number, adminId: number) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/admin/${adminId}/stores/delete`, { instance_name })).data;
    }

    public async authorize_link(instance_name: string, storeId: number, adminId: number, domain: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/admin/${adminId}/authorize/link`, { instance_name, domain })).data;
    }

    public async invoices(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/chargebee`)).data;
    }

    public async chargebee_reset_trail(storeId: string) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/chargebee/reset/trail`, {})).data;
    }

    public async get_nodes(storeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/nodes/list`)).data;
    }

    public async get_contents_defaults(storeId: number, type: string) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/nodes/contents/default/${type}`)).data;
    }

    public async get_contents(storeId: number, nodeId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/nodes/contents/${nodeId}`)).data;
    }

    public async save_node(storeId: number, nodeId: number, data: any) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/nodes/${nodeId}`, data)).data;
    }

    public async save_node_contents(storeId: number, nodeId: number, contents: any) {
        return (await this.axios.post(`${StoreApi.PREFIX}/${storeId}/nodes/contents/${nodeId}`, { contents })).data;
    }

    public async get_boxes(storeId: number, skinId: number) {
        return (await this.axios.get(`${StoreApi.PREFIX}/${storeId}/boxes/${skinId}`)).data;
    }

}