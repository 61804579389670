import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable } from "mobx";
import moment, { Moment } from "moment";
import consola from "../config/consola";
import { NavLink } from "react-router-dom";
import prettyBytes from "pretty-bytes";
import Table from "../ui/Table";
import { Row, Col, Button, Form, ButtonGroup, Alert } from "react-bootstrap";
import { timeout } from "../lib/utils/utils";

const logger = consola("StoresUsage");

@observer
export default class StoresUsage extends React.Component<{ store: Store }> {
    @observable
    from: Moment = moment();
    @observable
    to: Moment = moment();
    @observable
    storesList: any[] = [];

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();
    }

    @action
    init = async () => {
        await this.load_stores();
    };

    load_stores = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("stores_usage");
            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.storesList = (await this.props.store.storesApi.accounting_usage(this.from, this.to)).data;
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                logger.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    csv = async () => {
        try {
            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }
            
            const csv = (await this.props.store.storesApi.accounting_usage(this.from, this.to, true));
            var link = window.document.createElement("a");
            var data = new Blob([csv], { type: "text/csv" });

            link.href = window.URL.createObjectURL(data);
            link.download = `StoresUsage-${this.from.format("DD-MM-YYYY")}-${this.to.format("DD-MM-YYYY")}.csv`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                logger.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render() {
        const columns = [
            {
                Header: "Storeid",
                disableSortBy: false,
                accessor: "storeid",
            },
            {
                Header: "Domain",
                disableSortBy: false,
                accessor: "domain",
            },
            {
                Header: "Web traffic",
                disableSortBy: false,
                accessor: "web_traffic",
                actions: (row: any) => {
                    return <>{prettyBytes(row.original && row.original.web_traffic ? row.original.web_traffic : 0, { locale: "si" })}</>;
                },
            },
            {
                Header: "Web requests",
                disableSortBy: false,
                accessor: "web_requests",
            },
            {
                Header: "API traffic",
                disableSortBy: false,
                accessor: "api_traffic",
                actions: (row: any) => {
                    return <>{prettyBytes(row.original && row.original.api_traffic ? row.original.api_traffic : 0, { locale: "si" })}</>;
                },
            },
            {
                Header: "API requests",
                disableSortBy: false,
                accessor: "api_requests",
            },
            {
                Header: "Plan",
                disableSortBy: false,
                accessor: "ChargeBeePlan",
            },
            {
                Header: "Status",
                disableSortBy: false,
                accessor: "ChargeBeeStatus",
            },
            {
                Header: "Action",
                disableSortBy: true,
                actions: (row: any) => <NavLink to={"/store/" + row.original.storeid}>View</NavLink>,
            },
        ];

        return (
            <>
                <Row>
                    <Col md="3" className="mb-20">
                        <h2>Stores Usage</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Form.Group className="mb-3">
                            <Form.Label>From</Form.Label>
                            <Form.Control type="date" name="from" value={this.from.format("YYYY-MM-DD")} onChange={(e) => (this.from = moment(e.target.value, "YYYY-MM-DD"))} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>To</Form.Label>
                            <Form.Control type="date" name="to" value={this.to.format("YYYY-MM-DD")} onChange={(e) => (this.to = moment(e.target.value, "YYYY-MM-DD"))} />
                        </Form.Group>
                        <ButtonGroup>
                            <Button variant="primary" onClick={() => this.load_stores()}>
                                View
                            </Button>
                            <Button variant="secondary" onClick={() => this.csv()}>
                                CSV
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="mb-20">
                        <Table data={this.storesList} columns={columns} />
                    </Col>
                </Row>
            </>
        );
    }
}
