import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { ShopamineInstance } from "shared/models";

export default observer(function DeactivateStore(props: { popupHasError: boolean; popupErrorMessage: string; update: (reason: string) => void }) {
    const [reason, setReason] = useState({
        reason: ""
    });

    useEffect(() => {
        props.update(reason.reason);
    }, [reason]);

    return (
        <>
            <h2>Deactivate store</h2>

            <Alert variant="danger" show={props.popupHasError}>
                {props.popupErrorMessage}
            </Alert>

            <Form.Group className="mb-3">
                <Form.Label>Reason</Form.Label>
                <Form.Control type="text" value={reason.reason} onChange={(e) => setReason(Object.assign({}, reason, { reason: e.target.value }))} />
            </Form.Group>

        </>
    );
});
