import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import Table from "../ui/Table";
import { Row, Col, Alert, Button, Form, ListGroup } from "react-bootstrap";
import { timeout } from "../lib/utils/utils";
import Popup from "../ui/Popup";
import { Machine } from "shared/models";
import _ from "lodash";

@observer
export default class Machines extends React.Component<{ store: Store }> {
    @observable
    isLoading = true;
    @observable
    machines: Machine[] = [];

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    machine_popup = false;
    @observable
    machine_mode: "edit" | "new" = "new";
    @observable
    machine: Machine = {
        id: "",
        name: "",
        scope: [],
        secret: "",
        uuid: "",
    };

    @observable
    show_delete_confirm = false;
    @observable
    show_view = false;

    @observable
    machine_delete?: Machine;

    @observable
    scopes: { [id: string]: string } = {};

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();
    }

    @action
    init = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("machines");
            if (!this.props.store.can("users")) {
                throw new Error("No access!!!!");
            }

            this.isLoading = true;

            this.machines = (await this.props.store.machineApi.list()).data;
            this.scopes = (await this.props.store.machineApi.availbleScopes()).data;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    edit_machine = async (machine: Machine) => {
        try {
            if (!this.props.store.can("users")) {
                throw new Error("No access!!!!");
            }

            this.machine = machine;
            this.machine_popup = true;
            this.machine_mode = "edit";
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    new_machine = async () => {
        this.machine = {
            id: "",
            name: "",
            scope: [],
            secret: "",
            uuid: "",
        };
        this.machine_popup = true;
        this.machine_mode = "new";
    };

    @action
    view_machine = (machine: Machine) => {
        this.machine = machine;
        this.show_view = true;
    };

    @action
    update_machine = async () => {
        try {
            if (!this.props.store.can("users")) {
                throw new Error("No access!!!!");
            }

            let machine;
            if (this.machine_mode === "new") {
                machine = (await this.props.store.machineApi.add(this.machine)).data;
            } else {
                machine = (await this.props.store.machineApi.update(this.machine)).data;
            }

            console.log(machine);

            await this.init();

            this.machine_popup = false;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    delete_machine = async () => {
        try {
            if (!this.props.store.can("users")) {
                throw new Error("No access!!!!");
            }

            if (!this.machine_delete) {
                throw new Error("Select machine!!!");
            }

            await this.props.store.machineApi.delete(this.machine_delete);

            this.show_delete_confirm = false;

            await this.init();
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        }
    };

    @action
    onOpenDelete = (machine: Machine) => {
        this.machine_delete = machine;
        this.show_delete_confirm = true;
    };

    copy = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    render() {
        const columns = [
            {
                Header: "ID",
                disableSortBy: false,
                accessor: "id",
            },
            {
                Header: "Name",
                disableSortBy: false,
                accessor: "name",
            },
            {
                Header: "UUID",
                disableSortBy: false,
                accessor: "uuid",
            },
            {
                Header: "Action",
                disableSortBy: true,
                accessor: "deleted",
                actions: (row: any) => {
                    return (
                        <>
                            <a className="clickable" onClick={() => this.view_machine(row.original)}>
                                View machine
                            </a>
                            &nbsp;
                            <a className="clickable" onClick={() => this.edit_machine(row.original)}>
                                Edit machine
                            </a>
                            &nbsp;
                            <a className="clickable" onClick={() => this.onOpenDelete(row.original)}>
                                Delete machine
                            </a>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col className="mb-20">
                        <h2>Machines</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Button variant="primary" onClick={() => this.new_machine()}>
                            Add machine
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Table columns={columns} data={this.machines} />
                    </Col>
                </Row>

                <Popup onClose={action(() => (this.machine_popup = false))} onSave={this.update_machine} show={this.machine_popup} title={this.machine_mode === "new" ? "Add new machine" : "Edit machine"}>
                    <Alert show={this.popupHasError} variant="danger">
                        {this.popupErrorMessage}
                    </Alert>

                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={this.machine.name} onChange={action((e) => (this.machine.name = e.target.value))} />
                    </Form.Group>

                    <h4>Scopes</h4>

                    {_.map(this.scopes, (entry) => {
                        return (
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="switch"
                                    label={_.upperFirst(entry.replaceAll("_", " "))}
                                    checked={this.machine.scope.indexOf(entry) !== -1 ? true : false}
                                    onChange={action((e) => {
                                        if (e.target.checked) {
                                            this.machine.scope.push(entry);
                                        } else {
                                            this.machine.scope = _.remove(this.machine.scope, entry);
                                        }
                                    })}
                                />
                            </Form.Group>
                        );
                    })}
                </Popup>

                <Popup show={this.show_delete_confirm} onClose={action(() => (this.show_delete_confirm = false))} onSave={this.delete_machine} title="Delete machine?" save="Delete"></Popup>
                <Popup show={this.show_view} onClose={action(() => (this.show_view = false))} title="View machine">
                    <Row>
                        <Col>
                            <ListGroup>
                                <ListGroup.Item>ID: {this.machine.id}</ListGroup.Item>
                                <ListGroup.Item>Name: {this.machine.name}</ListGroup.Item>
                                <ListGroup.Item>
                                    Scope:
                                    <ul>
                                        {_.map(this.machine.scope, (a) => {
                                            return <li key={a}>{a}</li>;
                                        })}
                                    </ul>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    UUID: <p className="code">{this.machine.uuid}</p>
                                    <Button onClick={() => this.copy(this.machine.uuid)} variant="info" type="button">
                                        <i className="mdi mdi-content-copy"></i> Copy
                                    </Button>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    Secret: <pre className="code">{this.machine.secret}</pre>
                                    <Button onClick={() => this.copy(this.machine.secret)} variant="info" type="button">
                                        <i className="mdi mdi-content-copy"></i> Copy
                                    </Button>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Popup>
            </>
        );
    }
}
