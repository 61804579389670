import { observer } from "mobx-react";
import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

const Editable = styled.pre`
    background-color: #e7e7e7;
    border: 1px solid #849ab1;
    border-radius: 5px;

    width: 400px;
    min-height: 150px;
    padding: 3px;

    margin: 10px 0;
`;

export default observer(function StoreNotes(props: { store_data: string; set_store_data: (store_data: string) => void }) {
    const [data, setData] = useState({
        data: props.store_data,
    });

    React.useEffect(() => {
        setData({ data: props.store_data });
    }, [props.store_data]);

    return (
        <>
            <h3>Notes, data, info, ...</h3>
            <Editable suppressContentEditableWarning={true} contentEditable={true} onBlur={(e) => setData({ data: e.currentTarget.innerText })}>
                {data.data}
            </Editable>

            <Button variant="primary" onClick={() => props.set_store_data(data.data)}>
                Save
            </Button>
        </>
    );
});
