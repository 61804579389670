import { observer } from "mobx-react";
import React from "react";
import _ from "lodash";

export default observer(function Status(props: { store_info: any }) {

    let status = "UNKNOWN";

    if(props.store_info && props.store_info.chargebee) {
        status = props.store_info.chargebee.ChargeBeeStatus;
    } else if(props.store_info && props.store_info.chargebee) {
        status = props.store_info.subscription.status;
    } else if (props.store_info) {
        status = props.store_info.ChargeBeeStatus;
    }

    return (
        <>
            <b>Status:&nbsp;</b> {status}
        </>
    );
});
