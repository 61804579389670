import React from "react";
import { useTable, usePagination, useSortBy, useGlobalFilter, Column } from "react-table";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import GlobalFilter from "./table/GlobalFilter";
import { observer } from "mobx-react";

type ColumnInternal = Column | { disableSortBy: boolean; actions?: (row: any) => React.ReactNode | React.ReactNode[] };

export default observer(function Table(props: { columns: ColumnInternal[]; data: any[]; showFooter?: boolean; defaultPageSize?: number; pageSizes?: number[] }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state, preGlobalFilteredRows, setGlobalFilter } = useTable(
        {
            columns: props.columns as any,
            data: props.data,
            initialState: { pageIndex: 0, pageSize: props.defaultPageSize ? props.defaultPageSize : 50 } as any,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    ) as any;

    const { pageIndex, pageSize } = state;
    let pageSizes = [50, 100, 200, 500];

    if(props.pageSizes) {
        pageSizes = props.pageSizes;
    }

    // Render the UI for your table
    return (
        <div className="table-responsive">
            <GlobalFilter globalFilter={state.globalFilter} preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table mb-20">
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <>
                                                    &nbsp;
                                                    <ArrowDown />
                                                </>
                                            ) : (
                                                <>
                                                    &nbsp;
                                                    <ArrowUp />
                                                </>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row: any, i: any) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    if (cell.column.actions) {
                                        return <td {...cell.getCellProps()}>{cell.column.actions(row)}</td>;
                                    }

                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {(props.showFooter === undefined || props.showFooter) && (
                <div className="d-md-flex align-items-left">
                    <ul className="pagination mb-0 justify-content-center">
                        <li className="page-item">
                            <button className="btn btn-secodary page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {"<<"}
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="btn btn-secodary page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {"<"}
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="btn btn-secodary page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                {">"}
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="btn btn-secodary page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {">>"}
                            </button>
                        </li>
                    </ul>
                    <div className="text-md-left text-center mr-10 ml-10 lh-35">
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                        | Go to page:{" "}
                    </div>
                    <div className="text-md-left text-center mr-10">
                        <input
                            type="number"
                            className="form-control"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: "100px" }}
                        />
                    </div>
                    <div className="text-md-left text-center">
                        <select
                            className="form-select"
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}>
                            {pageSizes.map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </div>
    );
})
