import consola from 'consola';

consola.level = process.env.NODE_ENV === 'development' ? 5 : 3;

export default (scope?: string) => {
    if (!scope) {
        return consola.withScope("App");
    }

    return consola.withScope(scope);
}