import Api from "../utils/api";

export default class DeployApi extends Api {
    private static PREFIX = "/api/v1/deploy";

    status = async () => {
        try {
            return (await this.axios.get(`${DeployApi.PREFIX}/`)).data;
        } catch (eRaw) {
            const e = eRaw as any
            console.log(e);
            throw e.message || e;
        }
    }

    transfer = async (instance: string) => {
        try {
            return (await this.axios.post(`${DeployApi.PREFIX}/transfer`, { instance })).data;
        } catch (eRaw) {
            const e = eRaw as any
            console.log(e);
            throw e.message || e;
        }
    }

    rolling_restart = async (instance: string) => {
        try {
            return (await this.axios.post(`${DeployApi.PREFIX}/restart`, { instance })).data;
        } catch (eRaw) {
            const e = eRaw as any
            console.log(e);
            throw e.message || e;
        }
    }
}