import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";

export default observer(function Backup(props: { popupHasError: boolean; popupErrorMessage: string; update: (data: { email: string; send_backup: boolean; enc_password: string }) => void }) {
    const [data, setData] = useState({
        email: "",
        send_backup: false,
        enc_password: "",
    });

    useEffect(() => {
        props.update(data);
    }, [data]);

    return (
        <>
            <h2>Create backup</h2>

            <Alert variant="danger" show={props.popupHasError}>
                {props.popupErrorMessage}
            </Alert>

            <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" value={data.email} onChange={(e) => setData(Object.assign({}, data, { email: e.target.value }))} />
            </Form.Group>

            <Form.Check type="switch" label="Send backup" checked={data.send_backup} onChange={(e) => setData(Object.assign({}, data, { send_backup: e.target.checked }))} />

            {data.send_backup && (
                <Form.Group className="mb-3">
                    <Form.Label>ZIP passowrd</Form.Label>
                    <Form.Control type="text" value={data.enc_password} onChange={(e) => setData(Object.assign({}, data, { enc_password: e.target.value }))} />
                </Form.Group>
            )}
        </>
    );
});
