import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { ShopamineInstance } from "shared/models";

export default observer(function ActivateStore(props: { popupHasError: boolean; popupErrorMessage: string; instances: ShopamineInstance[]; loading_instances: string[]; update: (data: { instances: string[] }) => void }) {
    const initData: { instances: string[] } = {
        instances: props.loading_instances,
    };

    const [data, setData] = useState(initData);

    useEffect(() => {
        props.update(data);
    }, [data]);

    return (
        <>
            <h2>Activate store</h2>

            <Alert variant="danger" show={props.popupHasError}>
                {props.popupErrorMessage}
            </Alert>

            <Form.Select
                value={data.instances}
                multiple={true}
                onChange={(e) => {
                    const newInstances = [];
                    const len = e.target.options.length;
                    for (var i = 0; i < len; i++) {
                        const opt = e.target.options[i];

                        if (opt.selected) {
                            newInstances.push(opt.value);
                        }
                    }

                    setData(Object.assign({}, data, { instances: newInstances }));
                }}>
                {props.instances.map((p) => {
                    return <option key={p.name}>{p.name}</option>;
                })}
            </Form.Select>
        </>
    );
});
