import React from "react";
import { FilterValue, Row, useAsyncDebounce } from "react-table";
import { Col, Form, Row as BSRow } from "react-bootstrap";

export default function GlobalFilter(props: { preGlobalFilteredRows: Array<Row<any>>; globalFilter: any; setGlobalFilter: (filterValue: FilterValue) => void }) {
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
        props.setGlobalFilter(value || undefined);
    }, 200);

    return (
        <Col sm="4">
            <Form.Group as={BSRow} className="mb-3">
                <Form.Label column sm="2">
                    Search:
                </Form.Label>
                <Col sm="4">
                    <Form.Control
                        value={value || ""}
                        onChange={(e) => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        placeholder={`${count} records...`}
                        className="form-control"
                    />
                </Col>
            </Form.Group>
        </Col>
    );
}
