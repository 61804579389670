import Api from "../utils/api";

export default class ChargeBeeApi extends Api {
    private static PREFIX = "/api/v1/chargbee";

    invoice_pdf = async (invoice_id: string) => {
        try {
            return (await this.axios.get(`${ChargeBeeApi.PREFIX}/${invoice_id}/pdf`)).data;
        } catch (eRaw) {
            const e = eRaw as any
            console.log(e);
            throw e.message || e;
        }
    }
}