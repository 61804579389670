import { Machine } from "shared/models";
import Api from "../utils/api";

export default class MachinesApi extends Api {
    private static PREFIX = "/api/v1/machines";

    public async list() {
        return (await this.axios.get(`${MachinesApi.PREFIX}/`)).data;
    }

    public async add(machine: Machine) {
        return (await this.axios.post(`${MachinesApi.PREFIX}/add`, {
            machine
        })).data;
    }

    public async update(machine: Machine) {
        return (await this.axios.post(`${MachinesApi.PREFIX}/update`, {
            machine
        })).data;
    }

    public async delete(machine: Machine) {
        return (await this.axios.post(`${MachinesApi.PREFIX}/delete`, {
            machine
        })).data;
    }

    public async availbleScopes() {
        return (await this.axios.get(`${MachinesApi.PREFIX}/scops`)).data;
    }
}