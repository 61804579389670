import _ from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Table from "../../ui/Table";
import { DispatchDoamin } from "shared/models";

export default observer(function Users(props: { users: any[]; domains: string[]; impersonate: (adminId: number, domain: string) => void; dispatchDomain?: DispatchDoamin }) {
    const columns = [
        {
            Header: "Name",
            disableSortBy: false,
            accessor: "Name",
        },
        {
            Header: "Email",
            disableSortBy: false,
            accessor: "Email",
        },
        {
            Header: "Created",
            disableSortBy: false,
            accessor: (row: any) => {
                return moment(row.T_Updated).format("DD-MMM-YYYY HH:mm:ss");
            },
        },
        {
            Header: "Updated",
            disableSortBy: false,
            accessor: (row: any) => {
                return moment(row.T_Updated).format("DD-MMM-YYYY HH:mm:ss");
            },
        },
        {
            Header: "Action",
            disableSortBy: true,
            actions: (row: any) => {
                const user = row.original;

                return (
                    <>
                        <DropdownButton className="d-inline mx-2" title="Impersonate">
                            {user.Domain && user.Domain.length > 0 && !props.domains.includes(user.Domain) && <Dropdown.Item onClick={() => props.impersonate(user.ID, user.Domain)}>{user.Domain}</Dropdown.Item>}
                            {_.map(props.domains, (domain) => {
                                let url = "";
                                let displayName = "";
                                if (props.dispatchDomain) {
                                    if (props.dispatchDomain.useDomainValidator) {
                                        if (domain.indexOf(".") > 0) {
                                            url = `${domain}`;
                                            displayName = domain;
                                        } else {
                                            url = `${props.dispatchDomain.domain + "/" + domain}`;
                                            displayName = props.dispatchDomain.domain + "/" + domain;
                                        }
                                    } else {
                                        url = `${props.dispatchDomain.domain + "/" + domain}`;
                                        displayName = props.dispatchDomain.domain + "/" + domain;
                                    }
                                } else {
                                    url = `${domain}`;
                                    displayName = domain;
                                }

                                return (
                                    <Dropdown.Item key={domain} onClick={() => props.impersonate(user.ID, url)}>
                                        {displayName}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <h4>Users</h4>

            <Table data={props.users} columns={columns} defaultPageSize={10} pageSizes={[10, 20]} />
        </>
    );
});
