import Api from "../utils/api";

export default class AuthApi extends Api {
    private static PREFIX = "/api/v1/perms";
    private static PREFIX_EXTRA = "/api/v1/auth";

    check_perms = async () => {
        try {
            return (await this.axios.get(`${AuthApi.PREFIX}/`)).data;
        } catch (eRaw) {
            const e = eRaw as any
            console.log(e);
            throw e.message || e;
        }
    }

    login = async (username: string, password: string) => {
        return (await this.axios.post(`${AuthApi.PREFIX_EXTRA}/login`, {
            username,
            password
        })).data;
    }
}