import { observer } from "mobx-react";
import React from "react";
import _ from "lodash";

export default observer(function Plan(props: { store_info: any }) {
    let plan = "unknown";

    if (props.store_info && props.store_info.chargebee) {
        plan = props.store_info.chargebee.ChargeBeePlan;
    } else if (props.store_info && props.store_info.chargebee) {
        plan = props.store_info.subscription.plan_id;
    } else if (props.store_info) {
        plan = props.store_info.ChargeBeePlan;
    }

    return (
        <>
            <b>Plan:&nbsp;</b> {plan}
        </>
    );
});
