import { observer } from "mobx-react";
import React from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";

export default observer(function StripeSettings(props: { store_info: any; stripe: any; stripeAccounts: any[]; activate: () => void }) {
    if (props.stripe) {
        const account = _.find(props.stripeAccounts, { ID: props.stripe.Stripe_Account_ID });

        return (
            <>
                <h3>Stripe</h3>
                <p>
                    {account && (
                        <>
                            Store is using <b title={account.Publishable_Key}>{account.Account_Name}</b> account for stripe in <code>{account.Environment}</code> environment
                        </>
                    )}
                </p>

                <p>Fee: {props.stripe.Shopamine_Fee_Rate}%</p>

                <Button onClick={props.activate}>Adjust</Button>
            </>
        );
    } else {
        return (
            <>
                <h3>Stripe</h3>

                <p>Stripe is not activated</p>

                <Button onClick={props.activate}>Activate</Button>
            </>
        );
    }
});
