import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import { NavLink } from "react-router-dom";
import consola from "../config/consola";
import Table from "../ui/Table";
import { Row, Col, Button, Alert, Form, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import { Location, NavigateFunction, Params } from "react-router-dom";
import { withRouter } from "../lib/utils/react_utils";
import { timeout } from "../lib/utils/utils";
import Popup from "../ui/Popup";
import _ from "lodash";
//@ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
//@ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const logger = consola("stores");

@observer
class MetaAdminPermissions extends React.Component<{ store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    @observable
    isLoading = true;

    @observable
    permissions: any[] = [];
    @observable
    locale: any[] = [];

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    showEditPermission = false;

    @observable
    permission: any = {};
    @observable
    selectedLocale: string = "DF";

    @observable
    localizedName: { [id: string]: string } = {};
    @observable
    localizedText: { [id: string]: string } = {};

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.load();
    }

    @action
    load = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("meta");
            if (!this.props.store.can("meta")) {
                throw new Error("No access!!!!");
            }

            this.permissions = (await this.props.store.shopamineApi.get_meta_admin_user_permissions()).data;
            this.locale = (await this.props.store.shopamineApi.get_meta_locale()).data;
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                logger.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    onSaveEditPermission = async () => {
        try {
            if (!this.props.store.can("meta")) {
                throw new Error("No access!!!!");
            }

            this.permission.defaultName = this.localizedName.DF;
            this.permission.defaultText = this.localizedText.DF;

            for (const i in this.localizedName) {
                if (i === "DF") {
                    continue;
                }

                let yes = false;
                for (const j in this.permission.names) {
                    if (this.permission.names[j].locale === i) {
                        this.permission.names[j].content = this.localizedName[i];
                        yes = true;
                    }
                }

                if (!yes) {
                    this.permission.names.push({
                        locale: i,
                        content: this.localizedName[i],
                    });
                }
            }

            for (const i in this.localizedText) {
                if (i === "DF") {
                    continue;
                }

                let yes = false;
                for (const j in this.permission.texts) {
                    if (this.permission.texts[j].locale === i) {
                        this.permission.texts[j].content = this.localizedText[i];
                        yes = true;
                    }
                }

                if (!yes) {
                    this.permission.texts.push({
                        locale: i,
                        content: this.localizedText[i],
                    });
                }
            }
            const user_code = Number.parseInt(this.permission.user_code);

            this.permission.user_code = !isNaN(user_code) ? user_code : 0;

            await this.props.store.shopamineApi.update_meta_admin_user_permissions(this.permission);
            await this.load();

            this.showEditPermission = false;
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            this.showEditPermission = false;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                logger.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    onOpenEditPermission = (permission?: any) => {
        this.showEditPermission = true;

        this.localizedName = {};
        this.localizedText = {};

        console.log(toJS(permission));

        if (permission) {
            this.permission = permission;

            this.localizedName.DF = permission.defaultName;
            this.localizedText.DF = permission.defaultText;

            for (const entry of permission.names) {
                this.localizedName[entry.locale] = entry.content;
            }

            for (const entry of permission.texts) {
                this.localizedText[entry.locale] = entry.content;
            }
        } else {
            this.permission = {
                checked: false,
                required: false,
                names: [],
                texts: [],
            };
        }
    };

    @action
    onChangeLocale = (entry: string) => {
        this.selectedLocale = entry;
    };

    render() {
        const columns = [
            {
                Header: "ID",
                disableSortBy: false,
                accessor: "id",
            },
            {
                Header: "Name",
                disableSortBy: false,
                accessor: "defaultName",
            },
            {
                Header: "User code",
                disableSortBy: false,
                accessor: "user_code",
            },
            {
                Header: "Required",
                disableSortBy: false,
                actions: (row: any) => {
                    return <>{row.original.required ? "yes" : "no"}</>;
                },
            },
            {
                Header: "Checked",
                disableSortBy: false,
                actions: (row: any) => {
                    return <>{row.original.checked ? "yes" : "no"}</>;
                },
            },
            {
                Header: "Action",
                disableSortBy: true,
                actions: (row: any) => {
                    return (
                        <>
                            <Button
                                onClick={() => {
                                    this.onOpenEditPermission(row.original);
                                }}>
                                Edit
                            </Button>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col md="3" className="mb-20">
                        <h2>Meta admin permissions</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Button
                            variant="primary"
                            onClick={() => {
                                this.onOpenEditPermission();
                            }}>
                            Add entry
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col className="mb-20">
                        <Table data={this.permissions} columns={columns} />
                    </Col>
                </Row>

                <Popup onClose={action(() => (this.showEditPermission = false))} onSave={this.onSaveEditPermission} show={this.showEditPermission} title="Edit admin permission">
                    <Form.Group className="mb-3">
                        <Form.Label>User code</Form.Label>
                        <Form.Control type="text" value={this.permission.user_code} onChange={action((e) => (this.permission.user_code = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" onChange={action((e) => (this.permission.checked = e.target.checked))} checked={this.permission.checked} label="Checked" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" onChange={action((e) => (this.permission.required = e.target.checked))} checked={this.permission.required} label="Required" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <DropdownButton variant="outline-secondary" title="Select locale">
                            <Dropdown.Item onClick={() => this.onChangeLocale("DF")}>Default</Dropdown.Item>
                            <Dropdown.Divider />
                            {_.map(this.locale, (entry) => {
                                return (
                                    <Dropdown.Item key={entry.code} onClick={() => this.onChangeLocale(entry.code)}>
                                        {entry.name}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Name {this.selectedLocale}</Form.Label>
                        <Form.Control
                            placeholder="Name"
                            aria-describedby="basic-addon2"
                            value={this.localizedName[this.selectedLocale] === undefined ? "" : this.localizedName[this.selectedLocale]}
                            onChange={action((e) => {
                                this.localizedName[this.selectedLocale] = e.target.value;
                            })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Text {this.selectedLocale}</Form.Label>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote"],
                                link: {
                                    decorators: {
                                        openInNewTab: {
                                            mode: "manual",
                                            label: "Open in a new tab",
                                            attributes: {
                                                target: "_blank",
                                                rel: "noopener noreferrer",
                                            },
                                        },
                                    },
                                },
                            }}
                            data={this.localizedText[this.selectedLocale] === undefined ? "" : this.localizedText[this.selectedLocale]}
                            onReady={(editor: any) => {
                                console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });

                                // this.props.project.main && (this.props.project.main.text[0] = data)
                                this.localizedText[this.selectedLocale] = data;
                            }}
                        />
                    </Form.Group>
                </Popup>
            </>
        );
    }
}

export default withRouter(MetaAdminPermissions);
