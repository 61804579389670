import { observer } from "mobx-react";
import React from "react";
import Table from "../../ui/Table";
import { Col, Row } from "react-bootstrap";

export default observer(function Chargebee(props: { invoices: any[] }) {
    const columns = [
        {
            Header: "ID",
            disableSortBy: false,
            accessor: "id",
        },
        {
            Header: "Chargebee id",
            disableSortBy: false,
            accessor: "chargebee_id",
        },
        {
            Header: "status",
            disableSortBy: false,
            accessor: "invoice_status",
        },
        {
            Header: "Company",
            disableSortBy: false,
            accessor: "company_name",
        },
        {
            Header: "VAT ID",
            disableSortBy: false,
            accessor: "company_vat",
        },
        {
            Header: "Country",
            disableSortBy: false,
            accessor: "company_vat_prefix",
        },
        {
            Header: "Created",
            disableSortBy: false,
            accessor: "created",
        },
        {
            Header: "Updated",
            disableSortBy: false,
            accessor: "updated",
        },
        {
            Header: "Pantheon ID",
            disableSortBy: false,
            accessor: "pantheon_id",
        },
    ];

    return (
        <>
            <Row>
                <Col md="3" className="mb-20">
                    <h2>Chargebee Invoices</h2>
                </Col>
            </Row>

            <Row>
                <Col className="mb-20">
                    <Table data={props.invoices} columns={columns} />
                </Col>
            </Row>
        </>
    );
});
