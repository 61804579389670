import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import prettyBytes from "pretty-bytes";
import React, { useState } from "react";
import { Form, Button, ListGroup } from "react-bootstrap";

export default observer(function StoreAccounting(props: { load_store_index: (from: Moment, to: Moment) => void; store_index_data: any }) {
    const [data, setData] = useState({
        from: moment(),
        to: moment(),
    });

    let trafficPerDomain = [];

    if (props.store_index_data) {
        for (const i in props.store_index_data.domains) {
            const d = props.store_index_data.domains[i];
            const domains = [];

            for (const j in d) {
                domains.push(
                    <>
                        <ListGroup.Item>
                            <b>{j} requests:&nbsp;</b>
                            {d[j].requests}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <b>{j} traffic:&nbsp;</b>
                            {prettyBytes(d[j].traffic)}
                        </ListGroup.Item>
                    </>
                );
            }

            trafficPerDomain.push(
                <>
                    <h4>{i}</h4>
                    <ListGroup>{domains}</ListGroup>
                </>
            );
        }
    }

    return (
        <>
            <h3>Store accounting</h3>
            <Form.Group className="mb-3">
                <Form.Label>From</Form.Label>
                <Form.Control type="date" name="from" value={data.from.format("YYYY-MM-DD")} onChange={(e) => setData(Object.assign({}, data, { from: moment(e.target.value, "YYYY-MM-DD") }))} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>To</Form.Label>
                <Form.Control type="date" name="to" value={data.to.format("YYYY-MM-DD")} onChange={(e) => setData(Object.assign({}, data, { to: moment(e.target.value, "YYYY-MM-DD") }))} />
            </Form.Group>

            <Button variant="primary" onClick={(e) => props.load_store_index(data.from, data.to)}>
                View
            </Button>

            <ListGroup className="mt-20">
                <ListGroup.Item>
                    <b>Turnover:&nbsp;</b> {props.store_index_data && props.store_index_data.turnover} €
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Orders:&nbsp;</b> {props.store_index_data && props.store_index_data.numberOfOrders}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Items:&nbsp;</b> {props.store_index_data && props.store_index_data.numberOfItems}
                </ListGroup.Item>
            </ListGroup>

            <h4 className="mt-10">Disk space usage</h4>
            <ListGroup>
                <ListGroup.Item>
                    <b>Static:&nbsp;</b> {props.store_index_data && prettyBytes(props.store_index_data.diskspace.storeStatic.currentSize * 1000)}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Items:&nbsp;</b> {props.store_index_data && prettyBytes(props.store_index_data.diskspace.itemImages.currentSize * 1000)}
                </ListGroup.Item>
            </ListGroup>

            <h4 className="mt-10">Requests & Traffic</h4>
            <ListGroup>
                <ListGroup.Item>
                    <b>Requests API:&nbsp;</b> {props.store_index_data && props.store_index_data.requests.api}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Requests Web:&nbsp;</b> {props.store_index_data && props.store_index_data.requests.web}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Traffic API:&nbsp;</b> {props.store_index_data && prettyBytes(props.store_index_data.traffic.api)}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Traffic Web:&nbsp;</b> {props.store_index_data && prettyBytes(props.store_index_data.traffic.web)}
                </ListGroup.Item>
            </ListGroup>

            {trafficPerDomain}
        </>
    );
});
