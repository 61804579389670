import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { Broadcast, Files, LockFill, Server, UnlockFill } from "react-bootstrap-icons";
import { DispatchDoamin, DomainInfo } from "shared/models";

export default observer(function Domains(props: { domains: DomainInfo[]; dispatchDomain?: DispatchDoamin }) {
    const domains = [];

    for (const domain of props.domains) {
        let url = "";
        let displayName = "";
        if (props.dispatchDomain) {
            if (props.dispatchDomain.useDomainValidator) {
                if (domain.domain.indexOf(".") > 0) {
                    url = `http://${domain.domain}`;
                    displayName = domain.domain;
                } else {
                    url = `http://${props.dispatchDomain.domain + "/" + domain.domain}`;
                    displayName = props.dispatchDomain.domain + "/" + domain.domain;
                }
            } else {
                url = `http://${props.dispatchDomain.domain + "/" + domain.domain}`;
                displayName = props.dispatchDomain.domain + "/" + domain.domain;
            }
        } else {
            url = `http://${domain.domain}`;
            displayName = domain.domain;
        }

        domains.push(
            <ListGroup.Item key={displayName}>
                <h5>
                    <a href={url} target="_blank">
                        {displayName}
                    </a>
                    &nbsp;
                    {domain.isCertValid !== undefined && domain.isCertValid && !domain.includeInMap && <LockFill />}
                    {domain.isCertValid !== undefined && !domain.isCertValid && !domain.includeInMap && <UnlockFill />}
                </h5>
                <p className="m-0">
                    <span>{domain.locale}</span>&nbsp;
                    {domain.isCertValid !== undefined && domain.dns && (
                        <>
                            <Broadcast title="DNS" />
                            &nbsp;
                        </>
                    )}
                    {domain.isCertValid !== undefined && domain.wellKnwon && (
                        <>
                            <Server title="WellKnown" />
                            &nbsp;
                        </>
                    )}
                    {domain.isCertValid !== undefined && domain.hasCerts && (
                        <>
                            <Files title="Has certs" />
                            &nbsp;
                        </>
                    )}
                </p>
            </ListGroup.Item>
        );
    }

    return (
        <>
            <h4>Domains</h4>
            <ListGroup>{domains}</ListGroup>
        </>
    );
});
