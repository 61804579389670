import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css/normalize.css'
import './styles/main.scss'
import App from './App'
import Store from './lib/store'
import { toJS } from 'mobx'
import consola from './config/consola'

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";

consola().wrapConsole()

const store = new Store()

;(window as any).store = store
;(window as any).toJS = toJS

ReactDOM.render(<App store={store} />, document.getElementById('root'))
