import React from "react";
import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";
import GitEntry from "./GitInfo/GitEntry";
import { toJS } from "mobx";

export default observer(function GitInfo(params: {
    instancesInfo: {
        instances: any[];
        activateApi: string[];
    };
    templatesVersion: { [id: string]: any };
    currentBranches: { [id: string]: any };
    update: (instance: string) => void;
    log: (instance: string) => void;
    checkout: (instance: string) => void;
}) {
    const options = [];
    for (const instance of params.instancesInfo.instances) {
        const gitHash = params.templatesVersion[instance.name];
        const branch = params.currentBranches[instance.name];

        options.push(
            <Col className="mb-20" key={instance.name + gitHash}>
                <GitEntry checkout={params.checkout} log={params.log} update={params.update} instance={instance} gitHash={gitHash} branch={branch} />
            </Col>
        );
    }

    return <Row>{options}</Row>;
});
