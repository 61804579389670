import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import { NavLink } from "react-router-dom";
import consola from "../config/consola";
import Table from "../ui/Table";
import { Row, Col, Button, Alert, ButtonGroup } from "react-bootstrap";
import { Location, NavigateFunction, Params } from "react-router-dom";
import { withRouter } from "../lib/utils/react_utils";
import { getJSONFromArraBuffer, timeout } from "../lib/utils/utils";
import _ from "lodash";

const logger = consola("stores");

@observer
class FinanacesView extends React.Component<{ store: Store; type: "invoices" | "credit-notes"; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    @observable
    isLoading = true;
    @observable
    data: any[] = [];
    @observable
    status: any = {};

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();
    }

    @action
    init = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("finances");
            if (!this.props.router.params.id) {
                throw new Error("Missing id");
            }

            if (!this.props.store.can("finances")) {
                throw new Error("No access!!!!");
            }

            this.isLoading = true;

            if (this.props.type === "invoices") {
                this.data = (await this.props.store.financesApi.invoices(this.props.router.params.id)).data;
            } else {
                this.data = (await this.props.store.financesApi.credit_notes(this.props.router.params.id)).data;
            }

            this.status = (await this.props.store.financesApi.status(this.props.router.params.id)).data;
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                logger.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    exportCSV = async () => {
        try {
            if (!this.props.router.params.id) {
                throw new Error("Missing id");
            }

            if (!this.props.store.can("finances")) {
                throw new Error("No access!!!!");
            }

            let csv;
            if (this.props.type === "invoices") {
                csv = await this.props.store.financesApi.invoices_csv(this.props.router.params.id);
            } else {
                csv = await this.props.store.financesApi.credit_notes_csv(this.props.router.params.id);
            }

            var link = window.document.createElement("a");
            var data = new Blob([csv.data], { type: "text/csv" });

            link.href = window.URL.createObjectURL(data);
            link.download = `export4pantheon-${this.status.created}.csv`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                const data = getJSONFromArraBuffer(e.response.data);
                console.log("here", data);
                this.errorMessage = data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    exportVOD = async () => {
        try {
            if (!this.props.router.params.id) {
                throw new Error("Missing id");
            }

            if (!this.props.store.can("finances")) {
                throw new Error("No access!!!!");
            }

            let vod;
            if (this.props.type === "invoices") {
                vod = await this.props.store.financesApi.invoices_vod(this.props.router.params.id);
            } else {
                vod = await this.props.store.financesApi.credit_notes_vod(this.props.router.params.id);
            }

            var link = window.document.createElement("a");
            var data = new Blob([vod], { type: "text/xml" });

            link.href = window.URL.createObjectURL(data);
            link.download = `export4pantheon-${this.props.type}-${this.status.created}.xml`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                logger.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    invoicePDF = async (chargebee_id: string) => {
        try {
            const pdfInfo = (await this.props.store.chargebeeApi.invoice_pdf(chargebee_id)).data;

            window.open(pdfInfo.download_url);
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                logger.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render() {
        const columns = [
            {
                Header: "ID",
                disableSortBy: false,
                accessor: "id",
            },
            {
                Header: "Chargebee id",
                disableSortBy: false,
                accessor: "chargebee_id",
            },
            {
                Header: "Store id",
                disableSortBy: false,
                accessor: "store_id",
                cell: (row: any) => {
                    return (
                        <>
                            <NavLink to={"/store/" + row.store_id}>{row.store_id}</NavLink>
                        </>
                    );
                },
            },
            {
                Header: "status",
                disableSortBy: false,
                cell: (row: any) => {
                    if (this.props.type === "invoices") {
                        return row.invoice_status;
                    } else {
                        return row.credit_note_status;
                    }
                },
            },
            {
                Header: "plan",
                disableSortBy: false,
                actions: (row: any) => {
                    try {
                        let data = JSON.parse(new TextDecoder().decode(new Uint8Array(row.original.chargebee_blob.data)));
                        let line = _.find(data.line_items, (entry) => entry.entity_type === "plan");

                        return <>{line ? line.entity_id : "unknwon"}</>;
                    } catch (e) {
                        return <></>;
                    }
                },
            },
            {
                Header: "Company",
                disableSortBy: false,
                accessor: "company_name",
            },
            {
                Header: "VAT ID",
                disableSortBy: false,
                accessor: "company_vat",
            },
            {
                Header: "Country",
                disableSortBy: false,
                accessor: "company_vat_prefix",
            },
            {
                Header: "Created",
                disableSortBy: false,
                accessor: "created",
            },
            {
                Header: "Updated",
                disableSortBy: false,
                accessor: "updated",
            },
            {
                Header: "Pantheon ID",
                disableSortBy: false,
                accessor: "pantheon_id",
            },
            {
                Header: "Actions",
                disableSortBy: false,
                // accessor: "pantheon_id",
                actions: (row: any) => {
                    let json = new TextDecoder().decode(new Uint8Array(row.original.chargebee_blob.data));
                    let blob = new Blob([JSON.stringify(JSON.parse(json), undefined, 2)], { type: "application/json" });
                    let blobUrl = URL.createObjectURL(blob);

                    return (
                        <>
                            <a href={blobUrl} target="_blank">
                                View invoice json
                            </a>
                            &nbsp;
                            <a className="clickable" onClick={() => this.invoicePDF(row.original.chargebee_id)}>
                                PDF
                            </a>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col md="3" className="mb-20">
                        <h2>
                            {this.props.type === "invoices" ? "Invoices" : "Credit notes"} ({this.status.for_month}-{this.status.for_year})
                        </h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <ButtonGroup>
                            <Button variant="primary" onClick={this.exportVOD}>
                                VOD
                            </Button>

                            <Button variant="secondary" onClick={this.exportCSV}>
                                CSV
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="mb-20">
                        <Table data={this.data} columns={columns} />
                    </Col>
                </Row>
            </>
        );
    }
}

export default withRouter(FinanacesView);
