import Api from "../utils/api";

export default class FinancesApi extends Api {
    private static PREFIX = "/api/v1/finances";


    // restart = async (server?: string) => {
    //     return (await this.axios.post(`${FinancesApi.PREFIX}/restart`, { server })).data;
    // }

    // update = async (server?: string) => {
    //     return (await this.axios.post(`${FinancesApi.PREFIX}/update`, { server })).data;
    // }

    public async statuse() {
        return (await this.axios.get(`${FinancesApi.PREFIX}/status`)).data;
    }

    public async status(status_id: string) {
        return (await this.axios.get(`${FinancesApi.PREFIX}/status/${status_id}`)).data;
    }

    // public async porcess(status_id?: string) {
    //     return (await this.axios.post(`${FinancesApi.PREFIX}/invoices/process`, { status_id })).data;
    // }

    public async invoices(status_id: string) {
        return (await this.axios.get(`${FinancesApi.PREFIX}/invoices/${status_id}`)).data;
    }

    public async invoices_csv(status_id: string) {
        return (await this.axios.get(`${FinancesApi.PREFIX}/invoices/${status_id}/csv`, { responseType: 'arraybuffer' }));
    }

    public async invoices_vod(status_id: string) {
        return (await this.axios.get(`${FinancesApi.PREFIX}/invoices/${status_id}/vod`)).data;
    }

    public async credit_notes(status_id: string) {
        return (await this.axios.get(`${FinancesApi.PREFIX}/credit-notes/${status_id}`)).data;
    }

    public async credit_notes_csv(status_id: string) {
        return (await this.axios.get(`${FinancesApi.PREFIX}/credit-notes/${status_id}/csv`, { responseType: 'arraybuffer' }));
    }

    public async credit_notes_vod(status_id: string) {
        return (await this.axios.get(`${FinancesApi.PREFIX}/credit-notes/${status_id}/vod`)).data;
    }
}