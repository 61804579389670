import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { ListGroup } from "react-bootstrap";

export default observer(function ApiKeys(props: { apiKeys: any[] }) {
    const apikeys = [];

    for (const apiKey of props.apiKeys) {
        apikeys.push(<ListGroup.Item key={apiKey.Token}>
            <h5>{apiKey.Name} (<i>{apiKey.Scopes_Allowed.trim().split(" ").join(", ")}</i>)</h5>
            <span>{apiKey.Token}</span>
        </ListGroup.Item>);
    }

    return (
        <>
            <h4>API Keys</h4>
            <ListGroup>{apikeys}</ListGroup>
        </>
    );
});
