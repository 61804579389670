import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import moment, { Moment } from "moment";
import { Row, Col, Button, ButtonGroup, Alert } from "react-bootstrap";
import YearTurnover from "./Status/YearTurnover";
import { timeout } from "../lib/utils/utils";
import Deploy from "./Status/Deploy";

@observer
export default class Status extends React.Component<{ store: Store; match?: any }> {
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    actors_status: any = {};
    @observable
    templates_version: any = {};
    @observable
    caches: {
        cssCache: number;
        skinCache: { [id: number]: number };
    } = {
        cssCache: 0,
        skinCache: {},
    };
    @observable
    servers: any;
    @observable
    versions: { [id: string]: string } = {};

    @observable
    is_restarting = false;

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();

        this.props.store.on("actors.status", this.status);
    }

    @action
    init = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("status");
            this.templates_version = (await this.props.store.templatesApi.version()).data;
            this.servers = (await this.props.store.shopamineApi.info()).data;
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }

        for (const server of this.servers.instances) {
            try {
                const version = (await this.props.store.shopamineApi.version(server.name)).data;

                this.versions[server.name] = version[server.name];
            } catch (eRaw) {
                console.error(eRaw);
            }
        }
    };

    componentWillUnmount = () => {
        this.props.store.off("actors.status", this.status);
    };

    @action
    status = async (data: any) => {
        this.actors_status = data;
    };

    @action
    turnover_data = async (from: Moment, to: Moment) => {
        try {
            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            const csv = await this.props.store.storesApi.turnover_range_csv(from.format("DD-MM-YYYY"), to.format("DD-MM-YYYY"));

            var link = window.document.createElement("a");
            var data = new Blob([csv], { type: "text/csv" });

            link.href = window.URL.createObjectURL(data);
            link.download = `StoresUsage-${from.format("DD-MM-YYYY")}-${to.format("DD-MM-YYYY")}.csv`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    onRestart = async () => {
        await this.props.store.shopamineApi.restart();
    };

    onUpdate = async () => {
        await this.props.store.shopamineApi.update();
    };

    @action
    reloadCacheStatus = async () => {
        this.caches = (await this.props.store.skinApi.caches()).data;
    };

    @action
    restart = async (server: string) => {
        try {
            this.is_restarting = true;

            if (!this.props.store.can("shopamine_restart")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.shopamineApi.restart(server);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_restarting = false;
        }
    };

    @action
    update = async (server: string) => {
        try {
            this.is_restarting = true;

            if (!this.props.store.can("shopamine_restart")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.shopamineApi.update(server);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_restarting = false;
        }
    };

    render() {
        const shopamine_status = [];
        for (const server in this.actors_status.servers) {
            const status = this.actors_status.servers[server];
            const git_version = this.templates_version[server];
            const shopamine_version = this.versions[server];

            shopamine_status.push(
                <>
                    <h2 key={"name" + server}>{server}:</h2>
                    <p key={"git" + server}>Templates git version: {git_version}</p>
                    <div className="status" key={"actions" + server}>
                        <div>Shopamine: {shopamine_version}</div>
                        <div className="state-action">
                            <div className={"state-indicator " + (status === "ERR" ? "err" : "") + (status === "OK" ? "on" : "")}>&nbsp;</div>
                            &nbsp;
                            <button className="btn btn-small btn-info" onClick={() => this.restart(server)} disabled={!this.props.store.can("shopamine_restart")}>
                                restart
                            </button>
                            &nbsp;
                            <button className="btn btn-small btn-info" onClick={() => this.update(server)} disabled={!this.props.store.can("shopamine_restart")}>
                                update
                            </button>
                        </div>
                    </div>
                </>
            );
        }

        return (
            <>
                <Row>
                    <Col>
                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Deploy store={this.props.store} />
                    </Col>
                    <Col>
                        <Col md={3}>
                            <YearTurnover turnover_data={this.turnover_data} />
                        </Col>

                        <h2>Cache status</h2>

                        <Button variant="primary" onClick={this.reloadCacheStatus}>
                            Reload cache status
                        </Button>
                        <pre
                            style={{
                                overflow: "auto",
                                height: "250px",
                            }}>
                            {JSON.stringify(this.caches, undefined, 2)}
                        </pre>
                    </Col>
                    <Col>
                        <h2>Status</h2>

                        <h3>Services status:</h3>
                        <div className="status">
                            <div>Actors status:</div>
                            <div className="state-action">
                                <div className={"state-indicator " + (this.actors_status.status === false ? "err" : "") + (this.actors_status.status === true ? "on" : "")}>&nbsp;</div>
                                <div className="button"></div>
                            </div>
                        </div>

                        {shopamine_status}
                    </Col>
                </Row>
            </>
        );
    }
}
