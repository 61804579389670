import Api from "../utils/api";

export default class ShopamineApi extends Api {
    private static PREFIX = "/api/v1/shopamine";

    info = async () => {
        return (await this.axios.get(`${ShopamineApi.PREFIX}/`)).data;
    }

    restart = async (server?: string) => {
        return (await this.axios.post(`${ShopamineApi.PREFIX}/restart`, { server })).data;
    }

    update = async (server?: string) => {
        return (await this.axios.post(`${ShopamineApi.PREFIX}/update`, { server })).data;
    }

    version = async (server?: string) => {
        return (await this.axios.get(`${ShopamineApi.PREFIX}/version/${server}`)).data;
    }

    get_meta_admin_user_permissions = async () => {
        return (await this.axios.get(`${ShopamineApi.PREFIX}/meta/permissions`)).data;
    }

    update_meta_admin_user_permissions = async (data: any) => {
        return (await this.axios.post(`${ShopamineApi.PREFIX}/meta/permissions`, data)).data;
    }

    get_meta_locale = async () => {
        return (await this.axios.get(`${ShopamineApi.PREFIX}/meta/locale`)).data;
    }

    transalteUrl = async () => {
        return (await this.axios.post(`${ShopamineApi.PREFIX}/url/translate`)).data;
    }

}