import moment, { Moment } from "moment";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

export default function YearTurnover(props: { turnover_data: (from: Moment, to: Moment) => void }) {
    const [data, setData] = useState({
        from: moment(),
        to: moment(),
    });

    return (
        <>
            <h2>Yearly turnover</h2>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>From</Form.Label>
                <Form.Control type="date" name="from" value={data.from.format("YYYY-MM-DD")} onChange={(e) => setData(Object.assign({}, data, { from: moment(e.target.value, "YYYY-MM-DD") }))} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>To</Form.Label>
                <Form.Control type="date" name="to" value={data.to.format("YYYY-MM-DD")} onChange={(e) => setData(Object.assign({}, data, { to: moment(e.target.value, "YYYY-MM-DD") }))} />
            </Form.Group>

            <Button variant="primary" onClick={(e) => props.turnover_data(data.from, data.to)}>
                Download
            </Button>
        </>
    );
}
