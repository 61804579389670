import _ from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

export default observer(function StoreEmails(props: { emails: any[]; load_emails: (purchaseOnly: boolean) => void; store_users_emails_csv: () => void }) {
    const [data, setData] = useState({
        purchaseOnly: false,
    });

    return (
        <>
            <h3>Store user's emials</h3>

            <Form.Check
                type="switch"
                label="Purchase Only"
                checked={data.purchaseOnly}
                onChange={() => {
                    setData({
                        purchaseOnly: !data.purchaseOnly,
                    });

                    props.load_emails(!data.purchaseOnly);
                }}
            />

            <textarea className="form-control mb-15" style={{ width: "300px", height: "600px" }} value={_.map(props.emails, (item) => item.Email).join("\n")} onChange={(e) => {}}></textarea>

            <Button onClick={() => props.store_users_emails_csv()}>CSV</Button>
        </>
    );
});
