import _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Button, ButtonGroup, Col, Dropdown, DropdownButton, ListGroup, Row } from "react-bootstrap";

export default observer(function AdminView(props: { popupHasError: boolean; popupErrorMessage: string; admin: any; admin_view_stores: { storeId: number; storeName: string }[]; domains: string[]; getAuthFlowAuthorizationLink: (adminId: number, domain: string) => void; openLinkUserPopup: (adminId: number) => void; removeAdminStores: (adminId: number) => void }) {
    return (
        <>
            <Row>
                <Col className="mb-3">
                    <Alert variant="danger" show={props.popupHasError}>
                        {props.popupErrorMessage}
                    </Alert>

                    <h5>{props.admin.Name}</h5>
                    <span>{props.admin.Email}</span>
                    <p>AuthId: <b>{props.admin.AuthId}</b></p>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3">
                    <h3>List of connected stores</h3>
                    <ListGroup>
                        {_.map(props.admin_view_stores, (a) => (
                            <ListGroup.Item key={a.storeId}>
                                {a.storeId} - {a.storeName}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>

            <Row>
                <Col className="mb-3">
                    <ButtonGroup>
                        <Button variant="info" onClick={() => props.removeAdminStores(props.admin.ID)}>
                            Delete current store
                        </Button>
                        <Button variant="secondary" onClick={() => props.openLinkUserPopup(props.admin.ID)}>
                            Link user
                        </Button>
                        <DropdownButton as={ButtonGroup} title="Get auth flow authorization link">
                            {_.map(props.domains, (domain) => (
                                <Dropdown.Item key={domain} onClick={() => props.getAuthFlowAuthorizationLink(props.admin.ID, domain)}>
                                    {domain}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </ButtonGroup>
                </Col>
            </Row>
        </>
    );
});
