import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import _ from "lodash";

export default observer(function StripeConfigure(props: { popupHasError: boolean; popupErrorMessage: string; update: (data: { Shopamine_Fee_Rate: string; Stripe_Account_ID: string }) => void; stripeAccounts: any[], stripe: any }) {
    const [data, setData] = useState({
        Shopamine_Fee_Rate: props.stripe ? props.stripe.Shopamine_Fee_Rate : "0",
        Stripe_Account_ID: props.stripe ? props.stripe.Stripe_Account_ID : "",
    });

    useEffect(() => {
        props.update(Object.assign({}, props.stripe, data));
    }, [data]);

    return (
        <>
            <h2>Stripe Configuration</h2>

            <Alert variant="danger" show={props.popupHasError}>
                {props.popupErrorMessage}
            </Alert>

            <Form.Group className="mb-3">
                <Form.Label>Fee</Form.Label>
                <Form.Control type="text" value={data.Shopamine_Fee_Rate} onChange={(e) => setData(Object.assign({}, data, { Shopamine_Fee_Rate: e.target.value }))} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Shopamine stripe account</Form.Label>
                <Form.Select value={data.Stripe_Account_ID} onChange={(e) => setData(Object.assign({}, data, { Stripe_Account_ID: e.target.value }))}>
                    <option value="-1">Select ...</option>
                    {props.stripeAccounts.map((item) => (
                        <option key={item.ID} value={item.ID}>
                            {item.Account_Name}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        </>
    );
});
