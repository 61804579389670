import { Moment } from "moment";
import { StoreCreatorData } from "shared/models";
import Api from "../utils/api";

export default class StoresApi extends Api {
    private static PREFIX = "/api/v1/stores";

    list = async (filter: "active" | "disabled") => {
        return (await this.axios.get(`${StoresApi.PREFIX}/${filter === "disabled" ? filter : ''}`)).data;
    }

    public async accounting_usage(from: Moment, to: Moment, isCsv = false) {
        return (await this.axios.post(`${StoresApi.PREFIX}/usage` + (isCsv ? "?csv=true" : ""), {
            from: from.format("DD-MM-YYYY"), to: to.format("DD-MM-YYYY")
        })).data;
    }

    public async create_store(email: string, instance_name: string, data: StoreCreatorData) {
        return (await this.axios.post(`${StoresApi.PREFIX}/create/store`, {
            email, instance_name, data
        })).data;
    }

    public async create_store_samples(instance_name: string) {
        return (await this.axios.post(`${StoresApi.PREFIX}/create/store/samples`, {
            instance_name
        })).data;
    }

    public async admins(isCSV = false) {
        return (await this.axios.get(`${StoresApi.PREFIX}/admins?csv=${isCSV}`)).data;
    }

    public async turnover(year: string) {
        return (await this.axios.get(`${StoresApi.PREFIX}/turnover/year/${year}`)).data;
    }


    public async turnover_range(from: string, to: string) {
        return (await this.axios.get(`${StoresApi.PREFIX}/turnover/range/${from}/${to}`)).data;
    }

    public async turnover_range_csv(from: string, to: string) {
        return (await this.axios.get(`${StoresApi.PREFIX}/turnover/range/${from}/${to}/csv`)).data;
    }

    public async stripe_accounts() {
        return (await this.axios.get(`${StoresApi.PREFIX}/stripe/accounts`)).data;
    }
}

