import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import Table from "../ui/Table";
import { Row, Col, Alert, Button, Form } from "react-bootstrap";
import { timeout } from "../lib/utils/utils";
import Popup from "../ui/Popup";
import { Permission, User } from "shared/models";
import _ from "lodash";

@observer
export default class Users extends React.Component<{ store: Store }> {
    @observable
    isLoading = true;
    @observable
    users: User[] = [];
    @observable
    permissions: string[] = [];

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    user_popup = false;
    @observable
    user_mode: "edit" | "new" = "new";
    @observable
    user: User = {
        email: "",
        id: "",
        is_admin: false,
        password: "",
        username: "",
    };
    @observable
    user_permissions: { [id: string]: Permission | undefined } = {};
    @observable
    password: string = "";
    @observable
    passwordRepeat: string = "";

    @observable
    show_delete_confirm = false;
    @observable
    user_delete?: User;

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();
    }

    @action
    init = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("users");
            if (!this.props.store.can("users")) {
                throw new Error("No access!!!!");
            }

            this.isLoading = true;

            this.users = (await this.props.store.usersApi.list()).data;
            this.permissions = (await this.props.store.usersApi.getAvaliblePermissions()).data;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    edit_user = async (user: User) => {
        try {
            if (!this.props.store.can("users")) {
                throw new Error("No access!!!!");
            }

            const permissions = (await this.props.store.usersApi.listPermissions(user.id)).data;

            this.user_permissions = {};
            for (const permission of permissions) {
                this.user_permissions[permission.permission] = permission;
            }

            this.user = user;
            this.password = "";
            this.passwordRepeat = "";
            this.user_popup = true;
            this.user_mode = "edit";
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    new_user = async () => {
        this.user = {
            email: "",
            id: "",
            is_admin: false,
            password: "",
            username: "",
        };
        this.password = "";
        this.passwordRepeat = "";
        this.user_popup = true;
        this.user_mode = "new";
    };

    @action
    update_user = async () => {
        try {
            if (!this.props.store.can("users")) {
                throw new Error("No access!!!!");
            }

            let incPassword = false;
            if (this.user_mode === "new" || this.password.length > 2) {
                if (this.password !== this.passwordRepeat) {
                    throw new Error("Passwords do not match");
                }

                if (this.password.length < 12) {
                    throw new Error("Passwords must be a least 12 characters long");
                }

                incPassword = true;
            }

            let user;
            if (this.user_mode === "new") {
                user = (await this.props.store.usersApi.add(this.user, this.password)).data;
            } else {
                user = (await this.props.store.usersApi.update(this.user, incPassword ? this.password : undefined)).data;
            }

            console.log(user);

            const permissions = [];
            for (const permission of Object.values(this.user_permissions)) {
                if (permission === undefined) {
                    continue;
                }
                permissions.push(permission);
            }

            await this.props.store.usersApi.updatePermissions(user.id, permissions);

            await this.init();

            this.user_popup = false;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    delete_user = async () => {
        try {
            if (!this.props.store.can("users")) {
                throw new Error("No access!!!!");
            }

            if (!this.user_delete) {
                throw new Error("Select user!!!");
            }

            await this.props.store.usersApi.delete(this.user_delete);

            this.show_delete_confirm = false;

            await this.init();
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        }
    };

    @action
    onOpenDelete = (user: User) => {
        this.user_delete = user;
        this.show_delete_confirm = true;
    };

    render() {
        const columns = [
            {
                Header: "ID",
                disableSortBy: false,
                accessor: "id",
            },
            {
                Header: "Username",
                disableSortBy: false,
                accessor: "username",
            },
            {
                Header: "Email",
                disableSortBy: false,
                accessor: "email",
            },
            {
                Header: "Is admin",
                disableSortBy: false,
                accessor: (row: any) => (row.is_admin ? "yes" : "no"),
            },
            {
                Header: "Action",
                disableSortBy: true,
                accessor: "deleted",
                actions: (row: any) => {
                    return (
                        <>
                            <a className="clickable" onClick={() => this.edit_user(row.original)}>
                                Edit user
                            </a>
                            &nbsp;
                            <a className="clickable" onClick={() => this.onOpenDelete(row.original)}>
                                Delete user
                            </a>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col className="mb-20">
                        <h2>Users</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Button variant="primary" onClick={() => this.new_user()}>
                            Add user
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Table columns={columns} data={this.users} />
                    </Col>
                </Row>

                <Popup onClose={action(() => (this.user_popup = false))} onSave={this.update_user} show={this.user_popup} title={this.user_mode === "new" ? "Add new user" : "Edit user"}>
                    <Alert show={this.popupHasError} variant="danger">
                        {this.popupErrorMessage}
                    </Alert>

                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" value={this.user.username} onChange={action((e) => (this.user.username = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" value={this.user.email} onChange={action((e) => (this.user.email = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" value={this.password} onChange={action((e) => (this.password = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Repeat Password</Form.Label>
                        <Form.Control type="password" value={this.passwordRepeat} onChange={action((e) => (this.passwordRepeat = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check type="switch" label="Is admin" checked={this.user.is_admin} onChange={action((e) => (this.user.is_admin = e.target.checked))} />
                    </Form.Group>

                    <h4>Permissions</h4>

                    {_.map(this.permissions, (entry) => {
                        return (
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="switch"
                                    label={_.upperFirst(entry.replaceAll("_", " "))}
                                    checked={this.user_permissions[entry] ? true : false}
                                    onChange={action((e) => {
                                        if (e.target.checked) {
                                            this.user_permissions[entry] = {
                                                id: "0",
                                                user_id: "0",
                                                permission: entry,
                                            };
                                        } else {
                                            this.user_permissions[entry] = undefined;
                                        }
                                    })}
                                />
                            </Form.Group>
                        );
                    })}
                </Popup>

                <Popup show={this.show_delete_confirm} onClose={action(() => (this.show_delete_confirm = false))} onSave={this.delete_user} title="Delete user?" save="Delete"></Popup>
            </>
        );
    }
}
