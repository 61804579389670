import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import Store from "../lib/store";
import { timeout } from "../lib/utils/utils";

const ENTER_KEY = 13;

@observer
export default class Login extends React.Component<{ store: Store }> {
    @observable
    data = {
        username: "",
        password: "",
    };

    @observable
    is_logging_in = false;

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    @action
    login = async () => {
        try {
            this.is_logging_in = true;

            const data = (await this.props.store.authApi.login(this.data.username, this.data.password)).data;

            await this.props.store.update_session(data.token);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_logging_in = false;
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    _handleKeyDown = (event: any) => {
        switch (event.keyCode) {
            case ENTER_KEY:
                this.login();

                break;
            default:
                break;
        }
    };

    render() {
        return (
            <Container fluid className="mt-25">
                <Row className="justify-content-md-center">
                    <Col md={2}>
                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={this.data.username} onChange={action((e) => (this.data.username = e.target.value))} disabled={this.is_logging_in} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={this.data.password} onChange={action((e) => (this.data.password = e.target.value))} disabled={this.is_logging_in} />
                        </Form.Group>

                        <Button onClick={this.login} disabled={this.is_logging_in}>
                            Login
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
