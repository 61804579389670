import React from "react";
import { observer } from "mobx-react";
import { Button, ButtonGroup, Card } from "react-bootstrap";

export default observer(function GitEntry(params: { instance: any; gitHash: string; branch: string; update: (instance: string) => void; log: (instance: string) => void; checkout: (instance: string) => void }) {
    return (
        <Card style={{ width: "24rem" }}>
            <Card.Body>
                <Card.Title>{params.instance.name}</Card.Title>
                <Card.Text>
                    Branch: <b>{params.branch}</b>
                    <br />
                    <code>{params.gitHash}</code>
                </Card.Text>
                <ButtonGroup>
                    <Button
                        onClick={() => {
                            params.update(params.instance.name);
                        }}
                        variant="primary">
                        Pull
                    </Button>
                    <Button
                        onClick={() => {
                            params.log(params.instance.name);
                        }}
                        variant="info">
                        Log
                    </Button>
                    <Button
                        onClick={() => {
                            params.checkout(params.instance.name);
                        }}
                        variant="secondary">
                        Checkout
                    </Button>
                </ButtonGroup>
            </Card.Body>
        </Card>
    );
});
