import React from "react";
import { observer } from "mobx-react";
import { Button, ButtonGroup, Card, ListGroup } from "react-bootstrap";
import moment from "moment";

export default observer(function GitLog(params: {
    instancesInfo: {
        instances: any[];
        activateApi: string[];
    };
    templatesVersion: { [id: string]: any };
    currentBranches: { [id: string]: any };
    log: any[];
}) {
    const list = [];
    for (const item of params.log) {
        list.push(
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" key={item.commitId}>
                <div className="ms-2 me-auto">
                    <div className="fw-bold">{item.commitId}</div>
                    <pre>by {item.author} at {moment(item.time).format("DD-MMM-YYYY HH:mm:ss")}</pre>
                    <p className="mb-0">{item.message}</p>
                </div>
            </ListGroup.Item>
        );
    }

    return (
        <ListGroup as="ol" numbered>
            {list}
        </ListGroup>
    );
});
