import { observer } from "mobx-react";
import React from "react";
import Table from "../../ui/Table";

export default observer(function Backups(props: { backups: any[]; type: string; download: (key: string) => void; reload: () => void }) {
    const columns = [
        {
            Header: "Name",
            disableSortBy: false,
            accessor: "Key",
        },
        {
            Header: "Action",
            disableSortBy: true,
            actions: (row: any) => {
                const entry = row.original;

                return (
                    <>
                        <button className="btn btn-info" onClick={() => props.download(entry.Key)}>
                            Download
                        </button>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <h4>
                Backups {props.type}{" "}
                <button className="btn btn-link" onClick={() => props.reload()}>
                    Reload
                </button>
            </h4>

            <Table data={props.backups} columns={columns} defaultPageSize={10} pageSizes={[10, 20]} />
        </>
    );
});
