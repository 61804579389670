import React from "react";
import { observer } from "mobx-react";
import { Button, ButtonGroup, Card, ListGroup } from "react-bootstrap";
import moment from "moment";

export default observer(function GitCheckout(params: {
    instancesInfo: {
        instances: any[];
        activateApi: string[];
    };
    templatesVersion: { [id: string]: any };
    currentBranches: { [id: string]: any };
}) {


    return (
        <>Not yet implemented</>
    );
});
