import { observer } from "mobx-react";
import React from "react";
import { ListGroup } from "react-bootstrap";
import _ from "lodash";
import Plan from "./StoreInfo/Plan";
import Status from "./StoreInfo/Status";
import { DispatchDoamin } from "shared/models";

export default observer(function StoreInfo(props: { store_info: any; dispatchDomain?: DispatchDoamin }) {
    return (
        <>
            <ListGroup>
                <ListGroup.Item>
                    <b>Domains:&nbsp;</b>{" "}
                    {!props.dispatchDomain &&
                        props.store_info &&
                        _.map(props.store_info.domains, (item) => (
                            <span key={item.domain}>
                                <a href={`http://${item.domain}`} target="_blank">
                                    {item.domain}
                                </a>
                                ,{" "}
                            </span>
                        ))}
                    {props.dispatchDomain &&
                        props.store_info &&
                        _.map(props.store_info.domains, (item) => (
                            <span key={item.domain}>
                                <a href={`http://${props.dispatchDomain?.domain + "/" + item.domain}`} target="_blank">
                                    {props.dispatchDomain?.domain + "/" + item.domain}
                                </a>
                                ,{" "}
                            </span>
                        ))}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Store name:&nbsp;</b> {props.store_info && props.store_info.Store_Name}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Company:&nbsp;</b> {props.store_info && (props.store_info.subscription ? props.store_info.subscription.company_name : props.store_info.CompanyName)}
                </ListGroup.Item>
                <ListGroup.Item>
                    <Plan store_info={props.store_info} />
                </ListGroup.Item>
                <ListGroup.Item>
                    <Status store_info={props.store_info} />
                </ListGroup.Item>
            </ListGroup>
        </>
    );
});
